img.option-preview {
    border: 1px solid #000;
    margin-top: 1rem;
    margin-left: 1.8rem;
    cursor: pointer;
    opacity: 0.35;
    -webkit-filter: grayscale(100%) blur(4px); /* Safari 6.0 - 9.0 */
    filter: grayscale(100%) blur(4px);
    transition: all .15s ease-in-out;
}

img.option-preview.loaded {
    opacity: 1;
    -webkit-filter: grayscale(0%) blur(0); /* Safari 6.0 - 9.0 */
    filter: grayscale(0%) blur(0);
}

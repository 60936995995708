.theme-preview {
    border: 1px solid #000;
    min-height: 500px;
    margin: 1.2rem 2.4rem;
}

.theme-preview .theme-preview-statusbar {
    height: 26px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-right: .8rem;
    padding-left: .8rem;
}

.theme-preview .theme-preview-statusbar .mdi {
    margin-left: .1rem;
    margin-right: .1rem;
}

.theme-preview .theme-preview-toolbar {
    height: 56px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: .8rem;
    padding-right: .8rem;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}

.theme-preview .theme-preview-text-onbg {
    margin: 16px;
}

.theme-preview .theme-preview-card {
    height: 200px;
    margin: 16px;
    border-radius: 6px;
    padding: .8rem;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}

.theme-preview .theme-preview-snackbar {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: .8rem .8rem .4rem;
    height: 50px;
    margin: 16px;
    border-radius: 3px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.theme-preview .theme-preview-snackbar p {
    padding: 0;
    margin: 0;
}

.theme-preview .theme-preview-snackbar p.snackbar-btn {
    padding: 0;
    margin: 0;
    text-transform: uppercase;
    font-weight: 600;
    letter-spacing: 0.0588rem;
}

.theme-preview .theme-preview-fab {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin: 0;
}

.theme-preview .theme-preview-fab .fab {
    min-height: 1rem;
    height: 44px;
    margin-right: 16px;
    margin-bottom: 16px;
    border-radius: 99999px;
    padding-left: 1rem;
    padding-right: 1rem;
    text-transform: uppercase;
    font-weight: 600;
    letter-spacing: 0.0588rem;
    border: none;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
}

.theme-preview .theme-preview-navbar {
    height: 40px;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    padding-top: .3rem;
}

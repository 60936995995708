.screenshot {
    display: block;
    position: relative;
    width: 40%;
    padding: 0;
    height: fit-content;
    overflow: hidden;
    object-fit: contain;
    background-clip: content-box;
    margin: auto;
}

@media screen and (max-width: 720px) {
    .screenshot {
        width: 100%;
        margin-top: 0;
    }
}

@media screen and (max-width: 1120px) {
    .screenshot {
        width: 60%;
        margin-top: 1rem;
    }
}

.screenshot p {
    position: absolute;
    top: 45%;
    left: 35%;
}

.screenshot img {
    --border-color: rgba(0, 0, 0, .05);
    --shadow-color: rgba(0, 0, 0, .2);
    -webkit-filter: grayscale(0%) blur(0); /* Safari 6.0 - 9.0 */
    filter: grayscale(0%) blur(0);
    transition: all .35s ease-in-out;
    -webkit-animation: fadeIn .35s ease-in-out; /* Safari, Chrome and Opera > 12.1 */
    -moz-animation: fadeIn .35s ease-in-out; /* Firefox < 16 */
    -ms-animation: fadeIn .35s ease-in-out; /* Internet Explorer */
    -o-animation: fadeIn .35s ease-in-out; /* Opera < 12.1 */
    animation: fadeIn .35s ease-in-out;
    padding: 0;
    overflow: hidden;
    object-fit: contain;
    background-clip: content-box;
    border: 1px solid var(--border-color);
    -moz-box-shadow: 0 0 0 0 var(--border-color), 0 0.15rem 0.2rem -0.05rem var(--shadow-color), 0 0 0 0.05rem var(--border-color);
    -o-box-shadow: 0 0 0 0 var(--border-color), 0 0.15rem 0.2rem -0.05rem var(--shadow-color), 0 0 0 0.05rem var(--border-color);
    -webkit-box-shadow: 0 0 0 0 var(--border-color), 0 0.15rem 0.2rem -0.05rem var(--shadow-color), 0 0 0 0.05rem var(--border-color);
    box-shadow: 0 0 0 0 var(--border-color), 0 0.15rem 0.2rem -0.05rem var(--shadow-color), 0 0 0 0.05rem var(--border-color);
}

#frames .screenshot img {
    background-color: #35b5ef;
    --border-color: rgba(53, 181, 239, .05);
    --shadow-color: rgba(53, 181, 239, .2);
}

#kuper .screenshot img {
    background-color: #6673c0;
    --border-color: rgba(102, 115, 192, .05);
    --shadow-color: rgba(102, 115, 192, .2);
}

#blueprint .screenshot img {
    background-color: #4d8af0;
    --border-color: rgba(77, 138, 240, .05);
    --shadow-color: rgba(77, 138, 240, .2);
}

.screenshot img.hide {
    -webkit-filter: grayscale(100%) blur(5px); /* Safari 6.0 - 9.0 */
    filter: grayscale(100%) blur(5px);
    opacity: .1;
    transition: all .15s ease-in-out;
    -webkit-animation: fadeOut .15s ease-in-out; /* Safari, Chrome and Opera > 12.1 */
    -moz-animation: fadeOut .15s ease-in-out; /* Firefox < 16 */
    -ms-animation: fadeOut .15s ease-in-out; /* Internet Explorer */
    -o-animation: fadeOut .15s ease-in-out; /* Opera < 12.1 */
    animation: fadeOut .15s ease-in-out;
}

@keyframes fadeIn {
    0% { opacity: .1; }
    100% { opacity: 1; }
}

/* Firefox < 16 */
@-moz-keyframes fadeIn {
    0% { opacity: .1; }
    100% { opacity: 1; }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadeIn {
    0% { opacity: .1; }
    100% { opacity: 1; }
}

/* Internet Explorer */
@-ms-keyframes fadeIn {
    0% { opacity: .1; }
    100% { opacity: 1; }
}

/* Opera < 12.1 */
@-o-keyframes fadeIn {
    0% { opacity: .1; }
    100% { opacity: 1; }
}

@keyframes fadeOut {
    0% { opacity: 1; }
    100% { opacity: .1; }
}

/* Firefox < 16 */
@-moz-keyframes fadeOut {
    0% { opacity: 1; }
    100% { opacity: .1; }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadeOut {
    0% { opacity: 1; }
    100% { opacity: .1; }
}

/* Internet Explorer */
@-ms-keyframes fadeOut {
    0% { opacity: 1; }
    100% { opacity: .1; }
}

/* Opera < 12.1 */
@-o-keyframes fadeOut {
    0% { opacity: 1; }
    100% { opacity: .1; }
}

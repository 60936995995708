.hero .navbar {
    background-color: #fff;
}

.app-logo {
    max-width: 84px;
    display: block;
    margin: 1.6rem auto;
}

.box {
    padding-top: 1rem;
    padding-bottom: 1rem;
}

.mdi-icon-text {
    display: flex;
    flex-direction: row;
    align-items: center;
}

a {
    color: #3867d6;
}

a:hover,
a:focus,
a:active {
    color: #2d52ab;
    text-decoration: underline;
}

a.button:hover,
a.button:focus,
a.button:active {
    text-decoration: none;
}

.navbar-item,
.navbar-link {
    text-decoration: none !important;
}

.mdi.mdi-12px:before {
    font-size: 12px;
}

.mdi.mdi-14px:before {
    font-size: 14px;
}

.mdi.mdi-16px:before {
    font-size: 16px;
}

.mdi.mdi-20px:before {
    font-size: 20px;
}

.mdi.mdi-22px:before {
    font-size: 22px;
}

.app-title {
    display: -webkit-box;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    text-align: center;
    margin: 0 auto 4rem;
}

.app-title img {
    align-self: flex-start;
    margin: 0 .8rem !important;
}

.app-title h3 {
    margin-left: 1rem;
    margin-right: 2rem;
    margin-bottom: .4rem;
}

.features {
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 100%;
}

.has-same-height {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.features .box:not(:last-child) {
    margin-bottom: 1.2rem;
}

.feature {
    width: 80%;
    max-width: 80%;
    justify-self: center;
    align-self: center;
    place-self: center;
    margin-left: auto;
    margin-inline-start: auto;
}

@media screen and (max-width: 960px) {
    .feature {
        width: 100%;
        max-width: 100%;
    }
}

.feature-title {
    font-size: 1.2rem;
    margin-bottom: .6rem;
}

.features-arrows-container {
    margin-top: auto;
}

.features-arrows {
    margin-bottom: 1.4rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.features-arrows * {
    margin-left: .8rem;
    margin-right: .8rem;
}

.tabs a:hover,
.tabs a:focus,
.tabs a:active,
.tabs a.active,
.tabs a.is-active {
    text-decoration: none;
}

.efab {
    position: fixed;
    z-index: 10;
    bottom: 1.6rem;
    right: 1.6rem;
    background: #3867d6;
    color: #fff;
    font-size: 1.1rem;
    padding: .8rem 1.2rem;
    border-radius: 999999px;
    border: none;
    letter-spacing: 0.058rem;
    text-transform: uppercase;
    font-weight: 600;
    box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12), 0 3px 5px -1px rgba(0, 0, 0, 0.20);
    transition: all .25s ease-in-out;
    cursor: pointer;
}

.efab:hover,
.efab:focus {
    outline: none;
    transform: translateY(-.1rem);
    box-shadow: 0 12px 17px 2px rgba(0, 0, 0, 0.14), 0 5px 22px 4px rgba(0, 0, 0, 0.12), 0 7px 8px -4px rgba(0, 0, 0, 0.20);
}

.rc-color-picker-wrap .button {
    background-color: rgba(0, 0, 0, 0) !important;
    padding: .3rem .4rem;
}

.color-btn {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: .4rem;
    margin-bottom: .4rem;
}

.color-btn p {
    justify-self: center;
    margin-right: 1rem;
}

.setup .hero-footer {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
}

.setup .container .buttons {
    justify-content: center;
}

.setup .container .mobile-warning {
    margin-bottom: 3rem;
}

.setup .container .show-on-mobile {
    display: block;
}

@media screen and (min-width: 1024px) {
    .setup .container .show-on-mobile {
        display: none;
    }
}

.hero.is-fullheight-with-navbar.setup .hero-body,
.hero.is-fullheight.setup .hero-body,
.hero.is-halfheight.setup .hero-body {
    align-items: flex-start;
}

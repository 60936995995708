:root {
    --system-font: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
    --manrope-font: "Manrope", var(--system-font);
    --error: #eb3b5a;
    --warn: #f7b731;
}

.use-manrope {
    font-family: var(--manrope-font);
}

html, body {
    scroll-behavior: smooth;
    scroll-padding: 3.25rem 0 0 0;
    scroll-padding-top: 3.25rem;
}

body {
    margin: 0;
    font-family: var(--system-font);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #000;
    font-weight: 500;
}

h1 {
    font-size: 2.8rem;
    font-weight: 800;
    font-family: var(--manrope-font);
}

h2 {
    font-size: 2.4rem;
    font-weight: 700;
    font-family: var(--manrope-font);
}

h3 {
    font-size: 2.0rem;
    font-weight: 700;
    font-family: var(--manrope-font);
}

h4 {
    font-size: 1.6rem;
    font-weight: 600;
    font-family: var(--manrope-font);
}

h5 {
    font-size: 1.3rem;
}

h6, p {
    font-size: 1rem;
}

b {
    font-weight: 700;
}

.navbar-link, .navbar-item {
    font-family: var(--manrope-font);
    font-weight: 600;
}

.hero.is-fullheight .hero-body,
.hero.is-fullheight-with-navbar .hero-body,
.hero.is-halfheight .hero-body {
    align-items: center;
    display: flex;
}

.field.is-right .control {
    text-align: right;
}

.help {
    font-size: .9rem;
}

hr {
    margin: 0;
    padding: 0;
}

button, .button {
    font-family: var(--manrope-font);
    font-weight: 600;
}
